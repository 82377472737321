import React from "react"
import Layout from "../components/layout"
import moment from "moment"
import ShareFbButton from "../components/shareFbButton"
import ShareTwButton from "../components/shareTwButton"
import ShareLkButton from "../components/shareLkButton"

import BannerQuestionDesktop from "../images/cartouche1telOct2022.png"
import AbstractMedia from "../components/media/AbstractMedia"
import UrlService from "../services/global/urlService"
import { ATInternetTagService } from './../services/global/ATInternetService';

class ActualityTemplate extends React.Component {
  data
  url
  keywords
  media = {}

  async componentDidMount() {
    ATInternetTagService.sendPage({page:this.props.pageContext.actu.title ,page_chapter1:'actualites'})
  }

  render() {
    this.data = this.props.pageContext.actu
    this.canonical = UrlService.addSlashToEndOfUrl(this.data.canonicalUrl)
    this.url = this.props.location.href
    this.keywords = this.data.keywords != null ? this.data.keywords.split() : []
    this.media = this.props.pageContext.media

    return (
      <Layout
        titleSeo={this.data ? this.data.titleSEO : "actualite"}
        keywordsSeo={this.keywords}
        description={this.data ? this.data.description : ""}
        canonical={this.canonical}
      >
        <section className="pageHeader actu-header">
          <div className="pageHeaderContainer">
            <nav>
              <ul className="breadcrumb">
                <li className="breadcrumbItem">
                  <a
                    href="/"
                    className="breadcrumbItemLink breadcrumbItemLinkHome"
                  >
                    <i className="icon-mgc" />
                  </a>
                </li>
                <li className="breadcrumbItem">
                  <a
                    href="/actualites/"
                    className="breadcrumbItemLink breadcrumbItemLinkMiddle"
                  >
                    Actualités
                  </a>
                </li>
                <li className="breadcrumbItem">
                  <span className="breadcrumbItemLink">{this.data.title}</span>
                </li>
              </ul>
            </nav>
            <div className="ciblePubDesktop">
              <img
                src={BannerQuestionDesktop}
                alt={this.data.alt ? this.data.alt : ""}
              />
            </div>
          </div>

          <div>
            <h1 className="pageHeaderTitle">Actualités</h1>
          </div>
        </section>
        <div className="container">
          <section className="article">
            <div className="articleHeader">
              <div>
                <h1 className="articleHeaderTitle">{this.data.title}</h1>
                <p className="articleHeaderDate">
                  Publié le{" "}
                  {moment(this.data.formattedPublished).format("DD/MM/YYYY")}
                </p>
              </div>
              <div>
                <ul className="articleHeaderSocial">
                  <li className="articleHeaderSocialItem">
                    <ShareFbButton url={this.url} title={this.data.title} />
                  </li>
                  <li className="articleHeaderSocialItem">
                    <ShareTwButton url={this.url} title={this.data.title} />
                  </li>
                  <li className="articleHeaderSocialItem">
                    <ShareLkButton url={this.url} title={this.data.title} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="articleBody">
              <div className="articleBodyImage">
                <AbstractMedia
                  abstractMedia={this.data.media}
                  media={this.media}
                  alt={this.data.alt}
                  className="img-fluid"
                />
              </div>
              <div
                className="articleBodyTable"
                dangerouslySetInnerHTML={{ __html: this.data.content }}
              />
            </div>
            <div>
              <a href="/actualites/" className="return">
                <i className="fas fa-chevron-left" />Consulter toutes les actualités
              </a>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ActualityTemplate
